<template>
  <div class="custom-report-edit">
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">
        {{ $l.get("custom-report-edit.header") }}
      </h3>

      <CustomReportForm
        :customReport="data.customReport"
        :onSubmit="onSubmitForm"
        v-if="data && data.customReport"
      />
    </div>
  </div>
</template>

<script>
import SubmitFormInteractor from "@/business/custom-report-edit/submit-form";
import InitCustomReportEditScreenInteractor from "@/business/custom-report-edit/init-custom-report-edit";
import CustomReportEditScreenController from "@/adapters/controllers/screen-custom-report-edit";
import Loader from "@/application/components/Loader";
import CustomReportForm from "@/application/components/custom-report/CustomReportForm";
// import AutoComplete from "@/application/components/AutoComplete";

export default {
  screen_name: "custom-report-edit",
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initCustomReportEditScreen: null
      },
      data: null,
      isLoading: false
    };
  },
  components: { Loader, CustomReportForm },
  beforeMount() {
    this.controller = this.$injector.get(CustomReportEditScreenController);

    //{ INTERACTORS
    this.interactors.initCustomReportEditScreen = this.$injector.get(
      InitCustomReportEditScreenInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initCustomReportEditScreen.handle(id);
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmitForm() {
      const customReport = this.data.customReport;
      // console.log(customReport);
      this.interactors.submitForm.handle(customReport);
    }
  }
};
</script>

<style lang="scss"></style>
