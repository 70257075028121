<template>
  <div class="custom-report-form">
    <form @submit.prevent="() => {}">
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.general-data") }}</h3>
        </div>
        <vs-input
          :label-placeholder="
            $l.get('custom-report-form.name-field', 'capitalize')
          "
          :danger="isNameInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="customReport.name"
        />
      </vs-card>
    </form>
    <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
      <vs-button color="primary" type="filled" @click="onFormSubmit()">
        {{ $l.get("dictionary.submit-new") }}
      </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "custom-report-form",
  props: ["customReport", "onSubmit"],
  data() {
    return {
      isNameInvalid: false
    };
  },
  mounted() {},
  methods: {
    onFormSubmit() {
      this.isNameInvalid = this.customReport.name.length == 0;
      if (this.isNameInvalid) {
        return;
      } else {
        this.onSubmit();
      }
    }
  }
};
</script>

<style lang="scss">
.custom-report-form .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}
</style>
